// src/components/UserProfile/UserProfile.js
import React, { useState } from 'react';
import './UserProfile.css';
import TimeBankService from '../TimeBankService/TimeBankService';
const UserProfile = ({ user, onClose, isAdmin }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState({ ...user });

    const handleEditToggle = () => {
        if (isEditing) {
            setEditedUser({ ...user });  // Restaura os dados originais ao cancelar a edição
        }
        setIsEditing(!isEditing);
    };

    const handleInputChange = (e) => {
        setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
    };

    const saveChanges = () => {
        // Aqui você pode integrar uma API para salvar os dados
  
        setIsEditing(false);
    };

    return (
        <div className="user-profile">
            <div className="close-button" onClick={onClose}>&times;</div>
            <div className="profile-header">
                <img src={editedUser.image} alt={`${editedUser.firstName} ${editedUser.lastName}`} className="profile-image" />
                <div>
                    {isEditing ? (
                        <>
                            <input type="text" value={editedUser.firstName} onChange={handleInputChange} name="firstName" />
                            <input type="text" value={editedUser.lastName} onChange={handleInputChange} name="lastName" />
                        </>
                    ) : (
                        <h1>{editedUser.firstName} {editedUser.lastName}</h1>
                    )}
                    <p>{isEditing ? <input type="text" value={editedUser.position} onChange={handleInputChange} name="position" /> : editedUser.position}</p>
                    <p>{isEditing ? <input type="text" value={editedUser.location} onChange={handleInputChange} name="location" /> : editedUser.location}</p>
                </div>
            </div>
            <div className="profile-details">
                <section>
                    <h2>Personal Information</h2>
                    <div className="detail">
                        <strong>First Name:</strong> {isEditing ? <input type="text" value={editedUser.firstName} onChange={handleInputChange} name="firstName" /> : editedUser.firstName}
                        <strong>Last Name:</strong> {isEditing ? <input type="text" value={editedUser.lastName} onChange={handleInputChange} name="lastName" /> : editedUser.lastName}
                        <strong>Email:</strong> {isEditing ? <input type="text" value={editedUser.email} onChange={handleInputChange} name="email" /> : editedUser.email}
                        <strong>Phone:</strong> {isEditing ? <input type="text" value={editedUser.phone} onChange={handleInputChange} name="phone" /> : editedUser.phone}
                        <strong>Bio:</strong> {isEditing ? <input type="text" value={editedUser.bio} onChange={handleInputChange} name="bio" /> : editedUser.bio}
                    </div>
                </section>
                <section>
                    <h2>Address</h2>
                    <div className="detail">
                        <strong>Country:</strong> {isEditing ? <input type="text" value={editedUser.country} onChange={handleInputChange} name="country" /> : editedUser.country}
                        <strong>City/State:</strong> {isEditing ? <input type="text" value={editedUser.city} onChange={handleInputChange} name="city" /> : editedUser.city}
                        <strong>Postal Code:</strong> {isEditing ? <input type="text" value={editedUser.postalCode} onChange={handleInputChange} name="postalCode" /> : editedUser.postalCode}
                        <strong>TAX ID:</strong> {isEditing ? <input type="text" value={editedUser.taxId} onChange={handleInputChange} name="taxId" /> : editedUser.taxId}
                    </div>
                </section>
                <section>
                    <h2>W9 File Status</h2>
                    <div className="detail">
                        <strong>W9 Form Submitted:</strong> {isEditing ? <input type="checkbox" checked={editedUser.w9File} onChange={() => setEditedUser({ ...editedUser, w9File: !editedUser.w9File })} /> : (editedUser.w9File ? 'Yes' : 'No - User cannot work without W9 form.')}
                    </div>
                </section>
              
            </div>
            {isAdmin && <button onClick={handleEditToggle}>{isEditing ? "Cancel" : "Edit Profile"}</button>}
            {isEditing && <button onClick={saveChanges}>Save Changes</button>}
        </div>
    );
};

export default UserProfile;
