import axiosInstance from './axiosConfig';

const statusService = {
    getAll: async () => {
        try {
            const response = await axiosInstance.get('/status');
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar os status:', error);
            throw error;
        }
    },

    getById: async (id) => {
        try {
            const response = await axiosInstance.get(`/status/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar o status com ID ${id}:`, error);
            throw error;
        }
    }
};

export default statusService;
