import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { getUserById, updateUser } from '../../services/userService'; // Ajuste o caminho conforme necessário
import './ProfileView.css';

const ProfileView = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const userId = localStorage.getItem("userId"); // Substitua pelo ID do usuário atual
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await getUserById(userId);
                // Format the date to yyyy-MM-dd
                if (data.dateOfBirth) {
                    data.dateOfBirth = new Date(data.dateOfBirth).toISOString().split('T')[0];
                }
                setProfile(data);
            } catch (error) {
                console.error('Failed to fetch profile', error);
            }
            
        };

        fetchProfile();
    }, [userId]);

    const handleBackClick = () => {
        navigate('/');
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        try {
            await updateUser(userId, profile);
            setIsEditing(false);
        } catch (error) {
            console.error('Failed to update profile', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
    };

    if (!profile) {
        return <Loading />;
    }
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="profile-container">
            <div className="profile-header">
                <button className="back-button" onClick={handleBackClick}>&larr; Back</button>
                <h2>{isEditing ? 'Edit Profile' : 'Profile'}</h2>
            </div>
           
            <form className="profile-form">
                <div className="form-group">
                    <label>Name</label>
                    <input 
                        type="text" 
                        name="fullName" 
                        value={profile.fullName} 
                        readOnly={!isEditing}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label>Email Address</label>
                    <input 
                        type="email" 
                        name="email" 
                        value={profile.email} 
                        readOnly={!isEditing}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label>Phone Number</label>
                    <input 
                        type="text" 
                        name="telephone" 
                        value={profile.telephone} 
                        readOnly={!isEditing}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label>Date of Birth</label>
                    <input 
                        type="date" 
                        name="dateOfBirth" 
                        value={profile.dateOfBirth} 
                        readOnly={!isEditing}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <input 
                        type="text" 
                        name="country" 
                        value={profile.country} 
                        readOnly={!isEditing}
                        onChange={handleChange} 
                    />
                </div>
                {isEditing ? (
                    <button type="button" className="save-button" onClick={handleSaveClick}>Save Changes</button>
                ) : (
                    <button type="button" className="edit-button" onClick={handleEditClick}>Edit Profile</button>
                )}
            </form>
        </div>
    );
}

export default ProfileView;
