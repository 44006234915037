import axiosInstance from './axiosConfig';

const priorityService = {
    getAll: async () => {
        try {
            const response = await axiosInstance.get('/priority');
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar as prioridades:', error);
            throw error;
        }
    },

    getById: async (id) => {
        try {
            const response = await axiosInstance.get(`/priority/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar a prioridade com ID ${id}:`, error);
            throw error;
        }
    }
};

export default priorityService;
