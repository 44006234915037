import axiosInstance from './axiosConfig'; // Certifique-se de ajustar o caminho conforme necessário

const API_URL = '/Worker'; // Caminho relativo à baseURL definida no axiosInstance

const fetchWorkers = async () => {
    try {
        const response = await axiosInstance.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching workers:', error);
        throw error;
    }
};
const getWorkerById = async (workerId) => {
    const response =  await axiosInstance.get(`${API_URL}/${workerId}`);
    return response.data;
};

const addWorker = async (worker) => {
    const response = await axiosInstance.post(API_URL, worker);
    return response.data;
};
const updateWorker = async (workerId, worker) => {
    const response = await axiosInstance.put(`${API_URL}/${workerId}`, worker);
    return response.data;
};

const saveWorker = async (worker) => {
    try {
        if (worker.workerID === 0) {
            await axiosInstance.post(API_URL, worker);
        } else {
            await axiosInstance.put(`${API_URL}/${worker.workerID}`, worker);
        }
    } catch (error) {
        console.error('Error saving worker:', error);
        throw error;
    }
};
export default {
    addWorker,
    saveWorker,
    fetchWorkers,
    getWorkerById,
    updateWorker
};