import React, { useState, useEffect } from 'react';
import { getAllUsers } from '../../services/userService';
import { fetchEmployees } from '../../services/employeeService';
import WorkSessionTracker from '../WorkSessionTracker/WorkSessionTracker';
import './UserList.css';

const UserList = ({ onClose }) => {
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const [showEmployees, setShowEmployees] = useState(false);
  const [userFilter, setUserFilter] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [usarIDSelect, setUsarIDSelect] = useState('');
  const [isUser, setIsUser] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [entityKey, setEntityKey] = useState(0); // Initial key

  useEffect(() => {
    const fetchAllData = async () => {
      const usersData = await getAllUsers();
      const employeesData = await fetchEmployees();
      setUsers(usersData);
      setEmployees(employeesData);
    };
    fetchAllData();
  }, []);

  const handleAddHours = (entity) => {
    setUsarIDSelect(entity.id);
    setSelectedEntity(entity);
    setRefreshCount(prev => prev + 1);
    setIsUser(true);
  };

  const handleAddHoursWorks = (entity) => {
    setUsarIDSelect(entity.workerID);
    setRefreshCount(prev => prev + 1);

    setSelectedEntity(entity);
    setIsUser(false);
  };

  const handleCloseWorkSessionTracker = () => {
    setSelectedEntity(null);
  };

  return (
    <div className="user-list-container">
      <h2>User and Employee List</h2>
      <div className="close-button" onClick={onClose}>&times;</div>

      {/* User List */}
      <div className='UserListDiv'>
        <input
          type="text"
          placeholder="Search users"
          onChange={e => setUserFilter(e.target.value)}
        />
        <button onClick={() => setShowUsers(!showUsers)}>
          {showUsers ? 'Hide Users' : 'Show Users'}
        </button>
        {showUsers && (
          <table className="user-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.filter(user => user.fullName.toLowerCase().includes(userFilter.toLowerCase())).slice(0, 5).map(user => (
                <tr key={user.id}>
                  <td>{user.fullName}</td>
                  <td>{user.email}</td>
                  <td>
                    <button onClick={() => handleAddHours(user)}>Add Hours</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Employee List */}
      <div className='EmployeeList'>
        <input
          type="text"
          placeholder="Search employees"
          onChange={e => setEmployeeFilter(e.target.value)}
        />
        <button onClick={() => setShowEmployees(!showEmployees)}>
          {showEmployees ? 'Hide Employees' : 'Show Employees'}
        </button>
        {showEmployees && (
          <table className="user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {employees.filter(employee => employee.name.toLowerCase().includes(employeeFilter.toLowerCase())).slice(0, 5).map(employee => (
                <tr key={employee.employeeID}>
                  <td>{employee.name}</td>
                  <td>{employee.email}</td>
                  <td>
                    <button onClick={() => handleAddHoursWorks(employee)}>Add Hours</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Work Session Tracker Integration */}
      {selectedEntity && (
        <WorkSessionTracker
        user={isUser ? selectedEntity : null}
    selectedUserId={isUser ? usarIDSelect : null} // Pass selectedUserId if isUser is true
    workerId={!isUser ? usarIDSelect : null} // Pass workerId if isUser is false
    isUser={isUser} // Pass the isUser flag to clearly indicate which type of ID is active
    onClose={handleCloseWorkSessionTracker}
    refreshTrigger={refreshCount}
        />
      )}
    </div>
  );
};

export default UserList;
