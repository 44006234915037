import axiosInstance from './axiosConfig';

const getAllProjects = async () => {
  try {
    const response = await axiosInstance.get('/projects');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch projects', error);
    throw new Error('Failed to fetch projects');
  }
};

const getProjectById = async (id) => {
  try {
    const response = await axiosInstance.get(`/projects/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch project', error);
    throw new Error('Failed to fetch project');
  }
};

const createProject = async (project) => {
  try {
    const response = await axiosInstance.post('/projects', project);
    return response.data;
  } catch (error) {
    console.error('Failed to create project', error);
    throw new Error('Failed to create project');
  }
};

const updateProject = async (id, project) => {
  try {
    const response = await axiosInstance.put(`/projects/${id}`, project);
    return response.data;
  } catch (error) {
    console.error('Failed to update project', error);
    throw new Error('Failed to update project');
  }
};

const deleteProject = async (id) => {
  try {
    await axiosInstance.delete(`/projects/${id}`);
    return { id };
  } catch (error) {
    console.error('Failed to delete project', error);
    throw new Error('Failed to delete project');
  }
};

export { getAllProjects, getProjectById, createProject, updateProject, deleteProject };
