import React, { useState, useEffect } from 'react';
import { fetchReports, saveReport, fetchReportById } from '../../services/dailyReportService';
import workerService from '../../services/workerService';
import './DailyReport.css';

const WorkerSelection = ({ workers, selectedWorkerIds, handleWorkerSelection, removeWorker }) => (
    <div>
        <label>Select Worker:</label>
        <select onChange={handleWorkerSelection}>
            <option value="">Select a worker</option>
            {workers.map(worker => (
                <option key={worker.workerID} value={worker.workerID}>{worker.name}</option>
            ))}
        </select>
        <ul>
            {selectedWorkerIds.map((id, index) => {
                const worker = workers.find(w => w.workerID === id);
                return worker ? (
                    <li key={id}>
                        {worker.name} <button onClick={() => removeWorker(index)}>Remove</button>
                    </li>
                ) : null;
            })}
        </ul>
    </div>
);

const DayWorkForm = ({ dayWork, index, handleDayWorkChange }) => (
    <tr>
        <td><input type="text" name="building" value={dayWork.building} onChange={(e) => handleDayWorkChange(index, e)} /></td>
        <td><input type="text" name="work" value={dayWork.work} onChange={(e) => handleDayWorkChange(index, e)} /></td>
        <td><input type="text" name="done" value={dayWork.done} onChange={(e) => handleDayWorkChange(index, e)} /></td>
        <td><input type="text" name="problemDelays" value={dayWork.problemDelays} onChange={(e) => handleDayWorkChange(index, e)} /></td>
    </tr>
);

const DailyCheckForm = ({ dailyCheck, index, handleDailyCheckChange }) => (
    <tr>
        <td><input type="text" name="morning" value={dailyCheck.morning} onChange={(e) => handleDailyCheckChange(index, e)} /></td>
        <td><input type="text" name="afternoon" value={dailyCheck.afternoon} onChange={(e) => handleDailyCheckChange(index, e)} /></td>
        <td><input type="text" name="closing" value={dailyCheck.closing} onChange={(e) => handleDailyCheckChange(index, e)} /></td>
    </tr>
);

const DailyReport = ({ onClose }) => {
    const [reports, setReports] = useState([]);
    const [showReportForm, setShowReportForm] = useState(false);
    const [currentReport, setCurrentReport] = useState({
        weatherCondition: '',
        reportDate: '',
        supervisor: '',
        email: '',
        workerIds: [],
        dayWorks: [],
        dailyChecks: []
    });
    const [existingWorkers, setExistingWorkers] = useState([]);
    const [selectedWorker, setSelectedWorker] = useState('');
    const [workerError, setWorkerError] = useState('');

    useEffect(() => {
        loadReports();
        loadExistingWorkers();
    }, []);

    const loadReports = async () => {
        try {
            const data = await fetchReports();
            setReports(data);
        } catch (error) {
            console.error('Error loading reports:', error);
        }
    };

    const loadExistingWorkers = async () => {
        try {
            const data = await workerService.getAllWorkers();
            setExistingWorkers(data);
        } catch (error) {
            console.error('Error loading existing workers:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCurrentReport({ ...currentReport, [name]: value });
    };

    const handleDayWorkChange = (index, event) => {
        const { name, value } = event.target;
        const dayWorks = [...currentReport.dayWorks];
        dayWorks[index][name] = value;
        setCurrentReport({ ...currentReport, dayWorks });
    };

    const handleDailyCheckChange = (index, event) => {
        const { name, value } = event.target;
        const dailyChecks = [...currentReport.dailyChecks];
        dailyChecks[index][name] = value;
        setCurrentReport({ ...currentReport, dailyChecks });
    };

    const handleWorkerSelection = (event) => {
        const workerID = event.target.value;
        if (workerID && !currentReport.workerIds.includes(workerID)) {
            setCurrentReport({ ...currentReport, workerIds: [...currentReport.workerIds, workerID] });
        }
    };

    const removeWorker = (index) => {
        const workerIds = [...currentReport.workerIds];
        workerIds.splice(index, 1);
        setCurrentReport({ ...currentReport, workerIds });
    };

    const addNewDayWork = () => {
        const dayWorks = [...currentReport.dayWorks, { building: '', work: '', done: '', problemDelays: '' }];
        setCurrentReport({ ...currentReport, dayWorks });
    };

    const addNewDailyCheck = () => {
        const dailyChecks = [...currentReport.dailyChecks, { morning: '', afternoon: '', closing: '' }];
        setCurrentReport({ ...currentReport, dailyChecks });
    };

    const handleSaveReport = async () => {
        if (!currentReport.weatherCondition || !currentReport.reportDate || !currentReport.supervisor || !currentReport.email) {
            alert('Please fill in all the required fields.');
            return;
        }

        try {
            await saveReport(currentReport);
            loadReports();
            setCurrentReport({
                weatherCondition: '',
                reportDate: '',
                supervisor: '',
                email: '',
                workerIds: [],
                dayWorks: [],
                dailyChecks: []
            });
            setShowReportForm(false);
        } catch (error) {
            console.error('Error saving report:', error);
        }
    };

    const handleViewReport = async (report) => {
        try {
            const detailedReport = await fetchReportById(report.reportID);
            setCurrentReport({
                ...detailedReport,
                workerIds: detailedReport.workerIds || [],
                dayWorks: detailedReport.dayWorks || [],
                dailyChecks: detailedReport.dailyChecks || []
            });
            setShowReportForm(true);
        } catch (error) {
            console.error('Error loading report:', error);
        }
    };

    const handleCloseReport = () => {
        setShowReportForm(false);
        setCurrentReport({
            weatherCondition: '',
            reportDate: '',
            supervisor: '',
            email: '',
            workerIds: [],
            dayWorks: [],
            dailyChecks: []
        });
    };

    return (
        <div className="daily-report">
            <div className="close-button" onClick={onClose}>&times;</div>
            <h1>Daily Report</h1>

            {!showReportForm ? (
                <>
                    <div className="report-controls">
                        <button onClick={() => setShowReportForm(true)}>New Report</button>
                    </div>
                    <h2>Existing Reports</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Supervisor</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.length > 0 && reports.map(report => (
                                <tr key={report.reportID}>
                                    <td>{report.reportID}</td>
                                    <td>{report.reportDate}</td>
                                    <td>{report.supervisor}</td>
                                    <td>
                                        <button onClick={() => handleViewReport(report)}>View</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="report-form">
                    <div className="report-header">
                        <div className="logo">
                            <img src="logo.png" alt="Alpha Beta Finish Carpentry" />
                        </div>
                        <div className="report-info">
                            <label>
                                Weather Condition:
                                <input
                                    type="text"
                                    name="weatherCondition"
                                    value={currentReport.weatherCondition}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <label>
                                Date:
                                <input
                                    type="date"
                                    name="reportDate"
                                    value={currentReport.reportDate}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <label>
                                Supervisor:
                                <input
                                    type="text"
                                    name="supervisor"
                                    value={currentReport.supervisor}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <label>
                                Email:
                                <input
                                    type="email"
                                    name="email"
                                    value={currentReport.email}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="worker-selection-section">
                        <WorkerSelection
                            workers={existingWorkers}
                            selectedWorkerIds={currentReport.workerIds}
                            handleWorkerSelection={handleWorkerSelection}
                            removeWorker={removeWorker}
                        />
                    </div>
                    <div className="day-work-section">
                        <h2>Day Work</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Building</th>
                                    <th>Work</th>
                                    <th>Done</th>
                                    <th>Problem/Delays</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentReport.dayWorks.map((dayWork, index) => (
                                    <DayWorkForm
                                        key={index}
                                        dayWork={dayWork}
                                        index={index}
                                        handleDayWorkChange={handleDayWorkChange}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <button onClick={addNewDayWork}>Add Day Work</button>
                    </div>
                    <div className="daily-check-section">
                        <h2>Daily Check</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Morning</th>
                                    <th>Afternoon</th>
                                    <th>Closing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentReport.dailyChecks.map((dailyCheck, index) => (
                                    <DailyCheckForm
                                        key={index}
                                        dailyCheck={dailyCheck}
                                        index={index}
                                        handleDailyCheckChange={handleDailyCheckChange}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <button onClick={addNewDailyCheck}>Add Daily Check</button>
                    </div>
                    <div className="form-actions">
                        <button onClick={handleSaveReport}>Save Report</button>
                        <button onClick={handleCloseReport}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DailyReport;
