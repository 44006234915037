import React from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate do React Router v6
import './ComingSoon.css';
import logo from '../../logo192.png';
import Screenshot from '../../Screenshot 2024-08-16 104604.png';

const ComingSoon = () => {
    const navigate = useNavigate(); // Hook do React Router v6 para navegação

    const goToLogin = () => {
        navigate('/login'); // Redireciona para o componente de login
    };

    return (
        <div className="lamp-container">
            <div className="coming-soon">
                <div className="user-button-container">
                  
                </div>
                <div className="lamp"><img src={Screenshot} alt="Lamp Screenshot" /></div>
                <div className="light"></div>

                <h1>Coming Soon</h1>
                <img src={logo} alt="Company Logo" className="logoComingSoon" />
                <p className="subtitle">
                    In the meantime, please feel free to contact us by phone or email
                </p>
                <div className="contact-info">
                    <div className="contact-item">
                        <span className="icon">📞</span>
                        <a href="tel:+15089624045">508-962-4045</a>
                    </div>
                    <div className="contact-item">
                        <span className="icon">✉️</span>
                        <a href="mailto:info@alphabetafinish.com">info@alphabetafinish.com</a>
                    </div>
                    <button onClick={goToLogin} className="user-button">
                        User Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
