import axios from 'axios';
import config from '../config/config';

const axiosInstance = axios.create({
  baseURL:  config.API_URL,
 
});

// Adicionar um interceptador para adicionar o token JWT em todas as requisições
axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default axiosInstance;
