import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Extension for tables
import { getMaterials, createMaterial, updateMaterial, deleteMaterial, getMaterialTypes, getStorageLocations } from '../../services/materialService';
import { getAllProjects } from '../../services/projectService';
import imglogo from '../../logo192.png'; // Import the logo
import './MaterialViewer.css';

const MaterialViewer = () => {
    const [activeCategory, setActiveCategory] = useState(''); // Initially empty string
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProject, setSelectedProject] = useState(''); // Initially empty
    const [projects, setProjects] = useState([]);
    const [materialTypes, setMaterialTypes] = useState([]);
    const [storageLocations, setStorageLocations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [isCrudVisible, setIsCrudVisible] = useState(false);
    const [materials, setMaterials] = useState({});
    const [editingItem, setEditingItem] = useState(null);
    const [newItem, setNewItem] = useState({
        projectID: '',
        type: '',
        size: '',
        usage: '',
        storageLocationID: '',
        dateAdded: '',
        usedBy: '',
        quantity: 0,
        isUsed: false,
        usedAmount: 0,
        materialTypeID: '',
        expectedQuantity: 0,  // Expected quantity for the material
        receivedQuantity: 0,   // Received quantity for the material
        doorOpening: ''        // Door opening type (left, right, or empty if not applicable)
    });
    const [logo, setLogo] = useState(null); // To store the loaded logo

    useEffect(() => {
        fetchProjects();
        fetchMaterialTypes();
        fetchStorageLocations();
        fetchLogo();
    }, []);

    // Update materials whenever the project or category changes
    useEffect(() => {
        if (selectedProject) {
            fetchMaterials();
        }
    }, [selectedProject, activeCategory]);

    const fetchMaterials = async () => {
        try {
            const data = await getMaterials(selectedProject); // Use selectedProject to fetch materials
            console.log(data);

            // Grouping materials dynamically by materialTypeID
            const categorizedMaterials = data.reduce((acc, material) => {
                const materialType = materialTypes.find(mt => mt.typeID === material.materialTypeID);
                const categoryName = materialType ? materialType.typeName : 'Unknown';

                if (!acc[categoryName]) {
                    acc[categoryName] = [];
                }
                acc[categoryName].push(material);

                return acc;
            }, {});

            setMaterials(categorizedMaterials);

            // Set the first category as default if no category is active
            if (!activeCategory && materialTypes.length > 0) {
                setActiveCategory(materialTypes[0].typeName);
            }
        } catch (error) {
            console.error('Failed to fetch materials', error);
        }
    };

    const fetchProjects = async () => {
        try {
            const data = await getAllProjects();
            setProjects(data);
            setSelectedProject(data[0]?.id || ''); // Set the first project as default
        } catch (error) {
            console.error('Failed to fetch projects', error);
        }
    };

    const fetchMaterialTypes = async () => {
        try {
            const data = await getMaterialTypes();
            setMaterialTypes(data);

            // Set the first category as default
            if (!activeCategory && data.length > 0) {
                setActiveCategory(data[0].typeName);
            }
        } catch (error) {
            console.error('Failed to fetch material types', error);
        }
    };

    const fetchStorageLocations = async () => {
        try {
            const data = await getStorageLocations();
            setStorageLocations(data);
        } catch (error) {
            console.error('Failed to fetch storage locations', error);
        }
    };

    const fetchLogo = async () => {
        try {
            const response = await fetch(imglogo); // Path to the logo image
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogo(reader.result); // Convert to base64
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Failed to load logo', error);
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleProjectFilter = (event) => {
        setSelectedProject(event.target.value);
    };

    const toggleIsUsed = (index) => {
        const updatedMaterials = materials[activeCategory].map((material, i) => {
            if (i === index) {
                return { ...material, isUsed: !material.isUsed };
            }
            return material;
        });
        setMaterials(prevState => ({ ...prevState, [activeCategory]: updatedMaterials }));
    };

    const handleEdit = (item) => {
        setEditingItem(item);
        setNewItem(item);
        setIsCrudVisible(true);
    };

    const handleDelete = async (id) => {
        try {
            await deleteMaterial(id);
            fetchMaterials(); // Refresh the list after deletion
        } catch (error) {
            console.error('Failed to delete material', error);
        }
    };

    const handleSave = async () => {
        try {
            if (editingItem) {
                await updateMaterial(editingItem.materialID, newItem);
            } else {
                await createMaterial(newItem);
            }
            fetchMaterials(); // Refresh the list after saving
        } catch (error) {
            console.error('Failed to save material', error);
        } finally {
            setEditingItem(null);
            resetForm();
            setIsCrudVisible(false);
        }
    };

    const handleCancel = () => {
        setEditingItem(null);
        resetForm();
        setIsCrudVisible(false);
    };

    const resetForm = () => {
        setNewItem({
            projectID: '',
            type: '',
            size: '',
            usage: '',
            storageLocationID: '',
            dateAdded: '',
            usedBy: '',
            quantity: 0,
            isUsed: false,
            usedAmount: 0,
            materialTypeID: '',
            expectedQuantity: 0,  // Reset expected quantity
            receivedQuantity: 0,   // Reset received quantity
            doorOpening: ''        // Reset door opening to empty
        });
    };

    const filteredMaterials = materials[activeCategory]?.filter(material => {
        return material.type.toLowerCase().includes(searchTerm);
    }) || [];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMaterials = filteredMaterials.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredMaterials.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const renderMaterialList = () => {
        return currentMaterials.map((material, index) => {
            const materialType = materialTypes.find(mt => mt.typeID === material.materialTypeID);
            const storageLocation = storageLocations.find(sl => sl.id === material.storageID);
            return (
                <tr key={index}>
                    <td>{projects.find(project => project.id === material.projectID)?.name || 'N/A'}</td>
                    <td>{materialType ? materialType.typeName : 'N/A'}</td>
                    <td>{material.type}</td>
                    <td>{material.size}</td>
                    <td>{material.usage}</td>
                    <td>{storageLocation ? storageLocation.locationName : 'N/A'}</td>
                    <td>{new Date(material.dateAdded).toLocaleDateString()}</td>
                    <td>{material.usedBy}</td>
                    <td>{material.quantity}</td>
                    <td>{material.usedAmount}</td>
                    <td>{material.quantity - material.usedAmount}</td>
                    <td>{material.expectedQuantity}</td>  {/* Display expected quantity */}
                    <td>{material.receivedQuantity}</td>  {/* Display received quantity */}
                    <td>{material.doorOpening || 'N/A'}</td> {/* Display door opening type */}
                    <td>
                        <button className="edit-btn" onClick={() => handleEdit(material)}>Edit</button>
                    </td>
                    <td>
                        <button
                            className={`used-btn ${material.isUsed ? 'used' : 'not-used'}`}
                            onClick={() => toggleIsUsed(index)}
                        >
                            {material.isUsed ? 'Used' : 'Not Used'}
                        </button>
                    </td>
                    <td>
                        <button className="delete-btn" onClick={() => handleDelete(material.materialID)}>Delete</button>
                    </td>
                </tr>
            );
        });
    };

    // Function to generate the PDF
    const generatePDF = () => {
        const doc = new jsPDF();

        // Find the name of the selected project
        const selectedProjectName = projects.find(project => project.id === parseInt(selectedProject))?.name || 'Unknown Project';

        // Add logo and title in the header
        if (logo) {
            doc.addImage(logo, 'PNG', 10, 10, 50, 30); // Add logo
        }

        doc.setFontSize(18);
        doc.text(`Material List for ${selectedProjectName}`, 70, 25); // Add title with selected project name

        // Filter materials received and missing
        const receivedMaterials = filteredMaterials.filter(material => material.receivedQuantity > 0);
        const missingMaterials = filteredMaterials.filter(material => material.receivedQuantity < material.expectedQuantity);

        // Section "In Stock"
        doc.text('In Stock Materials', 70, 40);
        doc.autoTable({
            head: [['Project', 'Type', 'Size', 'Usage', 'Location', 'Date', 'Used By', 'Expected Quantity', 'Received Quantity', 'Door Opening']],
            body: receivedMaterials.map(material => [
                projects.find(project => project.id === material.projectID)?.name || 'N/A',
                material.type,
                material.size,
                material.usage,
                storageLocations.find(sl => sl.id === material.storageLocationID)?.locationName || 'N/A',
                new Date(material.dateAdded).toLocaleDateString(),
                material.usedBy,
                material.expectedQuantity,
                material.receivedQuantity,
                material.doorOpening || 'N/A'
            ]),
            startY: 50
        });

        // Section "Missing Materials"
        doc.text('Missing Materials', 70, doc.autoTable.previous.finalY + 20);
        doc.autoTable({
            head: [['Project', 'Type', 'Size', 'Usage', 'Location', 'Date', 'Used By', 'Expected Quantity', 'Missing Quantity', 'Door Opening']],
            body: missingMaterials.map(material => [
                projects.find(project => project.id === material.projectID)?.name || 'N/A',
                material.type,
                material.size,
                material.usage,
                storageLocations.find(sl => sl.id === material.storageLocationID)?.locationName || 'N/A',
                new Date(material.dateAdded).toLocaleDateString(),
                material.usedBy,
                material.expectedQuantity,
                material.expectedQuantity - material.receivedQuantity,  // Show what is missing
                material.doorOpening || 'N/A'
            ]),
            startY: doc.autoTable.previous.finalY + 30
        });

        doc.save('material-list.pdf');
    };

    return (
        <div className="material-viewer">
            <div className="filter-bar">
                <select onChange={handleProjectFilter} value={selectedProject}>
                    {projects.map((project) => (
                        <option key={project.id} value={project.id}>
                            {project.name}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder="Search by type..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            <div className="tabs">
                {Object.keys(materials).map((category, index) => (
                    <button
                        key={index}
                        className={`tab ${activeCategory === category ? 'active' : ''}`}
                        onClick={() => setActiveCategory(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <div className="material-list">
                <table>
                    <thead>
                        <tr>
                            <th>Project</th>
                            <th>Material Type</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Usage</th>
                            <th>Storage Location</th>
                            <th>Date</th>
                            <th>Used By</th>
                            <th>Quantity</th>
                            <th>Used Amount</th>
                            <th>Total Available</th>
                            <th>Expected Quantity</th>
                            <th>Received Quantity</th>
                            <th>Door Opening</th>
                            <th>Edit</th>
                            <th>Action</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderMaterialList()}
                    </tbody>
                </table>
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => paginate(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            <button
                className="toggle-crud-btn"
                onClick={() => setIsCrudVisible(!isCrudVisible)}
            >
                {isCrudVisible ? 'Hide Form' : 'Show Form'}
            </button>
            <button onClick={generatePDF}>Generate PDF with Header</button>
            {isCrudVisible && (
                <div className="crud-form">
                    <h3>{editingItem ? 'Edit Item' : 'Add New Item'}</h3>
                    <form>
                        <select
                            value={newItem.projectID}
                            onChange={(e) => setNewItem({ ...newItem, projectID: e.target.value })}
                        >
                            <option value="">Select Project</option>
                            {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                    {project.name}
                                </option>
                            ))}
                        </select>
                        <select
                            value={newItem.materialTypeID}
                            onChange={(e) => setNewItem({ ...newItem, materialTypeID: e.target.value })}
                        >
                            <option value="">Select Material Type</option>
                            {materialTypes.map((type) => (
                                <option key={type.typeID} value={type.typeID}>
                                    {type.typeName}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder="Type"
                            value={newItem.type}
                            onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="Size"
                            value={newItem.size}
                            onChange={(e) => setNewItem({ ...newItem, size: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="Usage"
                            value={newItem.usage}
                            onChange={(e) => setNewItem({ ...newItem, usage: e.target.value })}
                        />
                        <select
                            value={newItem.storageLocationID}
                            onChange={(e) => setNewItem({ ...newItem, storageLocationID: e.target.value })}
                        >
                            <option value="">Select Storage Location</option>
                            {storageLocations.map((location) => (
                                <option key={location.storageID} value={location.storageID}>
                                    {location.locationName}
                                </option>
                            ))}
                        </select>
                        <input
                            type="date"
                            placeholder="Date"
                            value={newItem.dateAdded}
                            onChange={(e) => setNewItem({ ...newItem, dateAdded: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="Used By"
                            value={newItem.usedBy}
                            onChange={(e) => setNewItem({ ...newItem, usedBy: e.target.value })}
                        />
                        <input
                            type="number"
                            placeholder="Quantity"
                            value={newItem.quantity}
                            onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
                        />
                        <input
                            type="number"
                            placeholder="Used Amount"
                            value={newItem.usedAmount}
                            onChange={(e) => setNewItem({ ...newItem, usedAmount: parseInt(e.target.value) })}
                        />
                        <div>
                            <label>Expected Quantity:</label>
                            <input
                                type="number"
                                value={newItem.expectedQuantity}
                                onChange={(e) => setNewItem({ ...newItem, expectedQuantity: parseInt(e.target.value) })}
                            />
                        </div>
                        <div>
                            <label>Received Quantity:</label>
                            <input
                                type="number"
                                value={newItem.receivedQuantity}
                                onChange={(e) => setNewItem({ ...newItem, receivedQuantity: parseInt(e.target.value) })}
                            />
                        </div>
                        <div>
                            <label>Door Opening:</label>
                            <input
                                type="text"
                                placeholder="Left or Right"
                                value={newItem.doorOpening}
                                onChange={(e) => setNewItem({ ...newItem, doorOpening: e.target.value })}
                            />
                        </div>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={newItem.isUsed}
                                    onChange={(e) => setNewItem({ ...newItem, isUsed: e.target.checked })}
                                />
                                Used
                            </label>
                        </div>
                        <button type="button" onClick={handleSave}>Save</button>
                        <button type="button" onClick={handleCancel}>Cancel</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default MaterialViewer;
