import axios from 'axios';
import config from '../config/config';

const API_URL = config.API_URL;


export const fetchInventory = async () => {
  try {
    const response = await axios.get(`${API_URL}/Inventory`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados do inventário', error);
    throw error;
  }
};

export const addMaterial = async (material) => {
  try {
    const response = await axios.post(`${API_URL}/Inventory`, material);
    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar material ao inventário', error);
    throw error;
  }
};

// Adicione outras funções de serviço conforme necessário, como atualizar e deletar materiais
