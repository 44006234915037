import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css';

function RegisterForm() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [document, setDocument] = useState('');
  const [w9, setW9] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!fullName || !email || !password) {
      setError('Full name, email, and password are required');
      return;
    }

    // Validação de formato de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format');
      return;
    }

    try {
      const response = await axios.post('https://alphabetafinish-001-site1.etempurl.com/api/Auth/register', {
        fullName,
        email,
        password,
        telephone: phoneNumber,
      });

      if (response.status === 200) {
        navigate('/login'); // Redireciona para a tela de login após o registro bem-sucedido
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Registration failed. Please try again later.');
      }
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <div className="form-group">
          <label>Full Name</label>
          <div className="input-container">
            <i className="fa fa-user"></i>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter your full name"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <div className="input-container">
            <i className="fa fa-envelope"></i>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Password</label>
          <div className="input-container">
            <i className="fa fa-lock"></i>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <div className="input-container">
            <i className="fa fa-phone"></i>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter your phone number"
            />
          </div>
        </div>

        {error && <div className="error">{error}</div>}
        <button className="buttonRegister" type="submit">Register</button>
      </form>
    </div>
  );
}

export default RegisterForm;
