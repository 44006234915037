// src/auth/authService.js

import { decodeJwt } from '../utils/jwtUtils';

export const isAuthenticated = () => {
  const token = getToken();
  return !!token;
};

export const isAdmin = () => {
  const token = getToken();
  if (!token) return false;

  const decodedToken = decodeJwt(token);
  return decodedToken?.role === 'admin'; // Verifica se o usuário é admin
};

export const getToken = () => {
  return localStorage.getItem('jwt'); // Ou recupere de um cookie, conforme necessário
};

export const login = (token) => {
  localStorage.setItem('jwt', token);
};

export const logout = () => {
  localStorage.removeItem('jwt');
};
