// authService.js
import axiosInstance from './axiosConfig';

export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post('/Auth/login', { email, password });
    const { token } = response.data;
    localStorage.setItem('jwtToken', token);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const register = async (userData) => {
  try {
    const response = await axiosInstance.post('/Auth/register', userData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUser = async () => {
  try {
    const response = await axiosInstance.get('/Auth/getUser'); // A URL deve ser ajustada conforme sua API
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user', error);
    throw new Error('Failed to fetch user');
  }
};
