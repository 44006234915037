import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/authService'; // Assumindo que login é uma função no authService
import './LoginForm.css';

function LoginForm({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError('Email and password are required');
      return;
    }

    try {
      const data = await login(email, password);
      if (data.token) {
        // Use o authService para manipular o armazenamento
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('autenticado', true);
        localStorage.setItem('roles', JSON.stringify(data.roles)); // Armazene as roles como uma string JSON

        onLogin(data.token, data.userId); // Notifique o pai sobre o login

        navigate('/'); // Redireciona para a tela principal
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      setError('Login failed. Please try again later.');
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register'); // Redireciona para a tela de registro
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email</label>
          <div className="input-container">
            <i className="fa fa-envelope"></i>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Password</label>
          <div className="input-container">
            <i className="fa fa-lock"></i>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
        </div>
        {error && <div className="error">{error}</div>}
        <button className="buttonLogin" type="submit">Login</button>
      </form>
      <button className="buttonRegister" onClick={handleRegisterRedirect}>Register</button>
    </div>
  );
}

export default LoginForm;
