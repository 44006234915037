import React, { useEffect, useState } from 'react';
import './PersonalHoursCard.css';
import { FaClock } from 'react-icons/fa';
import WorkSessionTracker from '../WorkSessionTracker/WorkSessionTracker';
import workSessionService from '../../services/workSessionService';
import { getUserById } from '../../services/userService';

const PersonalHoursCard = () => {
  const [userHours, setUserHours] = useState(0);
  const [userName, setUserName] = useState('');
  const [showTracker, setShowTracker] = useState(false);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserHours = async () => {
      try {
        const response = await workSessionService.getWeeklyTotalHours(userId, true);
        setUserHours(response.data);
      } catch (error) {
        console.error('Failed to fetch user hours', error);
      }
    };

    const fetchUserName = async () => {
      try {
        const response = await getUserById(userId);
        setUserName(response.fullName);
      } catch (error) {
        console.error('Failed to fetch user name', error);
      }
    };

    fetchUserHours();
    fetchUserName();
  }, [userId]);

  const handleCardClick = () => {
    setShowTracker(!showTracker); // Toggle visibility
  };

  const handleCloseTracker = () => {
    setShowTracker(false);
  };

  return (
    <>
      <div className="hours-card" onClick={handleCardClick}>
        <div className="card-header">
          <FaClock className="card-icon" />
          <h3>Personal Hours</h3>
        </div>
        <div className="card-body">
          <p><strong>User:</strong> {userName}</p>
          <p><strong>Total Hours This Week:</strong> {userHours}</p>
          <p><strong>Overtime:</strong> {userHours > 40 ? userHours - 40 : 0}</p>
          <div className="progress-container">
            <span>Progress</span>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${(userHours / 40) * 100}%` }}></div>
            </div>
          </div>
        </div>
      </div>
      {showTracker && (
        <WorkSessionTracker selectedUserId={userId} onClose={handleCloseTracker} />
      )}
    </>
  );
};

export default PersonalHoursCard;
