import React from 'react';
import './SearchComponent.css';

const SearchComponent = ({ onSearch }) => {
    return (
        <div className="search">
            <input 
                type="text" 
                placeholder="Search points..." 
                onChange={(e) => onSearch(e.target.value)}
            />
        </div>
    );
};

export default SearchComponent;
