import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import punchItemService from '../../services/punchItemService'; // Importa o serviço
import { getProjectById } from '../../services/projectService';
import './PointListComponent.css'; // Importando o CSS

const PointListComponent = ({ itemId, onBack }) => {
    const [item, setItem] = useState(null);
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Busca o Punch Item pelo ID
                const data = await punchItemService.getById(itemId);
                setItem(data);

                // Busca o projeto associado ao Punch Item usando projectId
                if (data.projectId) {
                    const projectData = await getProjectById(data.projectId);
                    setProject(projectData);
                }

                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar o Punch Item ou projeto:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [itemId]);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return format(new Date(dateString), 'MM/dd/yyyy');
    };

    if (loading) {
        return <div>Loading...</div>; // Pode personalizar o loading conforme necessário
    }

    if (!item) {
        return <div>Item não encontrado</div>;
    }

    return (
        <div className="punch-item-container">
            <header className="punch-item-header">
                <div className="company-info">
                    <img src="https://via.placeholder.com/150" alt="Company Logo" className="company-logo" />
                    <div>
                        <h2>{project?.name || 'Projeto não encontrado'}</h2>
                        <p>
                            {project?.location || 'Localização não encontrada'}<br />
                            (781) 356-7666
                        </p>
                    </div>
                </div>
                <div className="job-info">
                    <p>
                        <strong>Job #:</strong> {formatDate(item.dueDate)} {project?.name || 'Projeto não encontrado'}<br />
                        {project?.location || 'Localização não encontrada'}<br />
                 
                    </p>
                </div>
            </header>

            <section className="punch-item-details">
                <h3>
                    Punch Item Details
                </h3>
                <h4>
                    #{item.id}: {item.description}
                </h4>

                <div className="item-info">
                    <div className="info-group">
                        <p>
                            <strong>Type:</strong> <span>{item.type || 'N/A'}</span>
                        </p>
                        <p>
                            <strong>Location:</strong> <span>{item.location?.name || 'N/A'}</span>
                        </p>
                    </div>
                    <div className="info-group">
                        <p>
                            <strong>Date Created:</strong> <span>{formatDate(item.createdAt)}</span>
                        </p>
                        <p>
                            <strong>Due Date:</strong> <span>{formatDate(item.dueDate)}</span>
                        </p>
                    </div>
                    <div className="info-group">
                        <p>
                            <strong>Priority:</strong> <span>{item.priority?.name || 'N/A'}</span>
                        </p>
                        <p>
                            <strong>Status:</strong> <span>{item.status?.name || 'N/A'}</span>
                        </p>
                    </div>
                    <div className="info-group">
                        <p>
                            <strong>Creator:</strong> <span>{item.creator?.name || 'N/A'}</span>
                        </p>
                        <p>
                            <strong>Reference:</strong> <span>{item.reference || 'N/A'}</span>
                        </p>
                    </div>
                    <div className="info-group">
                        <p>
                            <strong>Punch Item Manager:</strong> <span>{item.punchItemManager?.name || 'N/A'}</span>
                        </p>
                        <p>
                            <strong>Final Approver:</strong> <span>{item.finalApprover?.name || 'N/A'}</span>
                        </p>
                    </div>
                    <div className="info-group">
                        <p>
                            <strong>Ball in Court:</strong> <span>{item.ballInCourt || 'N/A'}</span>
                        </p>
                        <p>
                            <strong>Assignee Name:</strong> <span>{item.assignee?.name || 'N/A'}</span>
                        </p>
                    </div>
                    <div className="info-group full-width">
                        <p>
                            <strong>Description:</strong> <span>{item.description}</span>
                        </p>
                    </div>
                </div>

                <div className="item-image">
                    <img src={item.imageUrl || 'https://via.placeholder.com/300'} alt="Item" />
                </div>

                <button onClick={onBack} className="back-btn">Back to List</button>
            </section>
        </div>
    );
};

export default PointListComponent;
