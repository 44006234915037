import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import WorkList from '../../components/WorkList/WorkList';
import PersonalHoursCard from '../../components/PersonalHoursCard/PersonalHoursCard';
import EmployeeHoursCard from '../../components/EmployeeHoursCard/EmployeeHoursCard';
import { getAllProjects } from '../../services/projectService';
import './Dashboard.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
 
  const currentDate = new Date();
  
  // Formata a data
  const options = { month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAllProjects();
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const handleProjectClick = (projectId) => {
    navigate(`/main-content/${projectId}`);

  };

  return (
    <div className="dashboard">
      <header className='headerDashboard'>
        <div className="header-left">
          <h1>Alpha Beta</h1>
        </div>
        <div className="header-right">
          <span className="date">{formattedDate}</span>
          <div className="view-options">
            <i className="fas fa-list"></i>
            <i className="fas fa-th-large"></i>
          </div>
        </div>
      </header>
      <div className="hours-cards">
        <PersonalHoursCard />
        <EmployeeHoursCard />
      </div>
      <div className="main-content">
      <div className="project-cards">
  {projects.map((project, index) => (
    <ProjectCard
      key={index}
      projectId={project.id} // Adiciona o ID do projeto como prop
      {...project}
      onClick={() => handleProjectClick(project.id)} // Passa o ID ao clicar
    />
  ))}
</div>
        <WorkList />
      </div>
    </div>
  );
};

export default Dashboard;
