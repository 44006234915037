import React, { useState, useEffect } from 'react';
import HeaderComponent from '../../components/PointListComponent/HeaderComponent/HeaderComponent';
import OverviewComponent from '../../components/PointListComponent/OverviewComponent/OverviewComponent';
import TransactionListComponent from '../../components/PointListComponent/TransactionListComponent/TransactionListComponent';
import FilterComponent from '../../components/PointListComponent/FilterComponent/FilterComponent';
import PointListComponent from '../../components/PointListComponent/PointListComponent';
import getAll from '../../services/punchItemService'; // Importa o serviço para buscar os pontos
import './PointListPage.css';

const PointListPage = () => {
    const [points, setPoints] = useState([]);
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [activeTab, setActiveTab] = useState('list'); // 'list' para a lista de pontos, 'view' para visualizar detalhes
    const [loading, setLoading] = useState(true);

    // Valores anteriores para comparação
    const [previousTotalPoints, setPreviousTotalPoints] = useState(0);
    const [previousCompletedPoints, setPreviousCompletedPoints] = useState(0);
    const [previousPendingPoints, setPreviousPendingPoints] = useState(0);

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const data = await getAll(); // Chama a API para buscar os pontos
                console.log('Pontos carregados:', data); // Verifica os dados carregados

                // Salva os valores atuais como valores anteriores antes de atualizar os pontos
                setPreviousTotalPoints(points.length);
                setPreviousCompletedPoints(points.filter(p => p.status === 'Completed').length);
                setPreviousPendingPoints(points.filter(p => p.status === 'Pending').length);

                setPoints(data);
                setFilteredPoints(data);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar pontos:', error);
                setLoading(false);
            }
        };

        fetchPoints();
    }, []);

    const handleSearch = (searchTerm) => {
        setFilteredPoints(points.filter(point => 
            point.description.toLowerCase().includes(searchTerm.toLowerCase())
        ));
    };

    const handleFilter = (status) => {
        setFilteredPoints(points.filter(point => point.status === status));
    };

    const handleViewDetails = (pointId) => {
        const point = points.find(p => p.id === pointId);
        setSelectedPoint(point);
        setActiveTab('view'); // Muda para a aba de visualização
    };

    const handleBackToList = () => {
        setActiveTab('list'); // Volta para a lista de pontos
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="point-list-page">
            <HeaderComponent />
            <div className="point-list-content">
                <div className="tabs">
                    <button className={`tab ${activeTab === 'list' ? 'active' : ''}`} onClick={() => setActiveTab('list')}>Point List</button>
                    {selectedPoint && (
                        <button className={`tab ${activeTab === 'view' ? 'active' : ''}`} onClick={() => setActiveTab('view')}>View Point</button>
                    )}
                </div>

                {activeTab === 'list' && (
                    <>
                        <OverviewComponent 
                            totalPoints={points.length} 
                            completedPoints={points.filter(p => p.status === 'Completed').length} 
                            pendingPoints={points.filter(p => p.status === 'Pending').length} 
                            previousTotalPoints={previousTotalPoints}
                            previousCompletedPoints={previousCompletedPoints}
                            previousPendingPoints={previousPendingPoints}
                        />
                        <FilterComponent onFilter={handleFilter} />
                        <TransactionListComponent 
                            points={filteredPoints} 
                            onSearch={handleSearch} 
                            onViewDetails={handleViewDetails} // Adiciona a função para visualizar os detalhes
                        />
                    </>
                )}

                {activeTab === 'view' && selectedPoint && (
                    <div>
                        <button className="back-btn" onClick={handleBackToList}>Back to List</button>
                        <PointListComponent item={selectedPoint} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PointListPage;
