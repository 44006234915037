import React, { useEffect, useState } from 'react';
import './EmployeeHoursCard.css';
import { FaUsers } from 'react-icons/fa';
import UserList from '../UserList/UserList';
import workSessionService from '../../services/workSessionService';

const EmployeeHoursCard = () => {
  const [employeeHours, setEmployeeHours] = useState(0);
  const [showUserList, setShowUserList] = useState(false);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchEmployeeHours = async () => {
      try {
        const response = await workSessionService.getWeeklyTotalHours(userId, false);
        setEmployeeHours(response.data);
      } catch (error) {
        console.error('Failed to fetch employee hours', error);
      }
    };

    fetchEmployeeHours();
  }, [userId]);

  const handleCardClick = () => {
    setShowUserList(!showUserList); // Toggle visibility
  };

  return (
    <>
      <div className="hours-card" onClick={handleCardClick}>
        <div className="card-header">
          <FaUsers className="card-icon" />
          <h3>Employee Hours</h3>
        </div>
        <div className="card-body">
          <p><strong>Total Hours This Week:</strong> {employeeHours}</p>
          <p><strong>Overtime:</strong> {employeeHours > 40 ? employeeHours - 40 : 0}</p>
          <div className="progress-container">
            <span>Progress</span>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${(employeeHours / 40) * 100}%` }}></div>
            </div>
          </div>
        </div>
      </div>
      {showUserList && (
        <div className="user-list-container">
          <UserList onClose={() => setShowUserList(false)} />
        </div>
      )}
    </>
  );
};

export default EmployeeHoursCard;
