import React from 'react';
import './HeaderComponent.css';

const HeaderComponent = ({ children }) => {
    return (
        // <div className="header-container">
        //     <div className="header-background">
        //         <img 
        //             src="https://via.placeholder.com/1200x300" 
        //             alt="Header Background" 
        //             className="header-image" 
        //         />
        //     </div>
        //     <div className="header-content">
        //         <h1 className="header-title">Weekly design goals</h1>
        //         <p className="header-breadcrumb">All Task / Design project / Weekly design goals</p>
        //         <div className="header-actions">
        //             <div className="header-avatars">
        //                 <img src="https://via.placeholder.com/40" alt="User 1" className="avatar" />
        //                 <img src="https://via.placeholder.com/40" alt="User 2" className="avatar" />
        //                 <img src="https://via.placeholder.com/40" alt="User 3" className="avatar" />
        //                 <span className="avatar-count">+5</span>
        //             </div>
        //             <button className="share-btn">Share</button>
        //         </div>
        //     </div>
        //     {/* Renderiza os componentes filhos passados para o HeaderComponent */}
        //     <div className="children-content">
        //         {children}
        //     </div>
        // </div>
        <div>{children}</div>
    );
};

export default HeaderComponent;
