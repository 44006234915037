// src/components/Loading.js
import React, { useState, useEffect } from 'react';
import './Loading.css';

const messages = [
  "Carregando recursos...",
  "Preparando a experiência...",
  "Quase lá...",
  "Só mais um momento...",
  "Agradecemos sua paciência..."
];

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 2; // Aumentando 2% a cada 100ms para acelerar o carregamento
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 1000); // Mudando as mensagens a cada 1 segundo

    return () => clearInterval(messageInterval);
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-bar">
        <div className={`loading-progress ${progress >= 100 ? 'complete' : ''}`} style={{ width: `${progress}%` }}></div>
      </div>
      <div className="loading-text">{progress}%</div>
      <div className="loading-message">{messages[messageIndex]}</div>
    </div>
  );
};

export default Loading;
