import axios from 'axios';

import config from '../config/config';

const API_URL = config.API_URL;

const getDailySummary = (id, isUserId) => {
  const url = `${API_URL}/WorkSessions/daily-summary/worker/${id}`;
  return axios.get(url);
};

const getAllSessions = () => {
  return axios.get(API_URL);
};

const getSessionById = (id) => {
  return axios.get(`${API_URL}/${id}`);
};

const startSession = (session) => {
  return axios.post(`${API_URL}/WorkSessions/start`, session, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const startLunchBreak = (sessionId) => {
  return axios.post(`${API_URL}/WorkSessions/start-lunch/${sessionId}`);
};

const endLunchBreak = (sessionId) => {
  return axios.post(`${API_URL}/WorkSessions/end-lunch/${sessionId}`);
};

const endSession = (sessionId) => {
  return axios.post(`${API_URL}/WorkSessions/end/${sessionId}`);
};

const getWeeklyTotalHours = (id, isUserId) => {
  const url = `${API_URL}/WorkSessions/weekly-total-hours/${isUserId ? 'user' : 'worker'}/${id}`;
  return axios.get(url);
};

const getActiveSessions = () => {
  return axios.get(`${API_URL}/WorkSessions/active`);
};

const getWorkerHours = async (workerId, startDate, endDate) => {
  try {
    const response = await axios.get(`${API_URL}/WorkSessions/worker/${workerId}/hours`, {
      params: {
        startDate,
        endDate
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching worker hours:', error);
    throw error;
  }
};

// Novo método para buscar trabalhadores ativos
const getActiveWorkers = async () => {
  try {
    const response = await axios.get(`${API_URL}/Worker`);
    return response.data;
  } catch (error) {
    console.error('Error fetching active workers:', error);
    throw error;
  }
};

const workSessionService = {
  getDailySummary,
  getAllSessions,
  getSessionById,
  startSession,
  startLunchBreak,
  endLunchBreak,
  endSession,
  getWeeklyTotalHours,
  getActiveSessions,
  getWorkerHours,
  getActiveWorkers,
};

export default workSessionService;
