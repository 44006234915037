import axiosInstance from './axiosConfig'; // Certifique-se de ajustar o caminho conforme necessário

const API_URL = '/Worker'; // Caminho relativo à baseURL definida no axiosInstance

export const fetchEmployees = async () => {
    try {
        const response = await axiosInstance.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching employees:', error);
        throw error;
    }
};

export const saveEmployee = async (employee) => {
    try {
        if (employee.employeeID === '') {
            await axiosInstance.post(API_URL, employee);
        } else {
            await axiosInstance.put(`${API_URL}/${employee.employeeID}`, employee);
        }
    } catch (error) {
        console.error('Error saving employee:', error);
        throw error;
    }
};

export const deleteEmployee = async (employeeID) => {
    try {
        await axiosInstance.delete(`${API_URL}/${employeeID}`);
    } catch (error) {
        console.error('Error deleting employee:', error);
        throw error;
    }
};

export const updateEmployeeHours = async (employeeID, hours) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/${employeeID}/hours`, { hours });
        return response.data;
    } catch (error) {
        console.error('Error updating employee hours:', error);
        throw error;
    }
};

// Certifique-se de exportar todas as funções
export default {
    fetchEmployees,
    saveEmployee,
    deleteEmployee,
    updateEmployeeHours
};
