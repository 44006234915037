import React, { useState, useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import SidebarComponent from '../../components/SidebarComponent/SidebarComponent';
import TrackInventory from '../../components/TrackInventory/TrackInventory';
import UserProfile from '../../components/UserProfile/UserProfile';
import WorkItemManager from '../../components/WorkItemManager/WorkItemManager';
import PointListPage from '../PointListPage/PointListPage';
import MaterialViewer from '../../components/MaterialViewer/MaterialViewer';
import './DashboardPageSlideBar.css';
import DailyReport from '../../components/DailyReport/DailyReport';
import UserList from '../../components/UserList/UserList';

const DashboardPageSlideBar = () => {
    const [activeComponent, setActiveComponent] = useState('TrackInventory');
    const navigate = useNavigate();
    const { projectId } = useParams();

    useEffect(() => {
        if (activeComponent === 'Dashboard') {
            navigate('/');
        }
    }, [activeComponent, navigate]);

    const renderComponent = () => {
        switch (activeComponent) {
            case 'TrackInventory':
                return <MaterialViewer projectID={projectId} />;
            case 'PointList':
                return <PointListPage />;
            case 'DailyReport':
                return <DailyReport />;
            case 'UserProfile':
                return <UserProfile />;
            case 'UserManager':
                return <UserList />;
            case 'WorkItemManager':
                return <WorkItemManager />;

            default:
                return <div>Select a component from the sidebar</div>;
        }
    };

    return (
        <div className="dashboard-page">
            <SidebarComponent onSelectComponent={setActiveComponent} />
            <div className="main-view">
                <div className="RenderDiv">
                    {renderComponent()}
                </div>
            </div>
        </div>
    );
};

export default DashboardPageSlideBar;
