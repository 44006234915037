import axiosInstance from './axiosConfig';

const punchItemService = {
    getAll: async () => {
        try {
            const response = await axiosInstance.get('/punchitem');
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar todos os Punch Items:', error);
            throw error;
        }
    },

    getById: async (id) => {
        try {
            const response = await axiosInstance.get(`/punchitem/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar Punch Item com ID ${id}:`, error);
            throw error;
        }
    },

    getByProject: async (projectId) => {
        try {
            const response = await axiosInstance.get(`/punchitem/by-project/${projectId}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar Punch Items por Projeto ID ${projectId}:`, error);
            throw error;
        }
    },

    getByStatus: async (statusId) => {
        try {
            const response = await axiosInstance.get(`/punchitem/by-status/${statusId}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar Punch Items por Status ID ${statusId}:`, error);
            throw error;
        }
    },

    getByPriority: async (priorityId) => {
        try {
            const response = await axiosInstance.get(`/punchitem/by-priority/${priorityId}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar Punch Items por Prioridade ID ${priorityId}:`, error);
            throw error;
        }
    },

    create: async (punchItem) => {
        try {
            const response = await axiosInstance.post('/punchitem', punchItem);
            return response.data;
        } catch (error) {
            console.error('Erro ao criar Punch Item:', error);
            throw error;
        }
    },

    update: async (id, punchItem) => {
        try {
            await axiosInstance.put(`/punchitem/${id}`, punchItem);
        } catch (error) {
            console.error(`Erro ao atualizar Punch Item com ID ${id}:`, error);
            throw error;
        }
    },

    delete: async (id) => {
        try {
            await axiosInstance.delete(`/punchitem/${id}`);
        } catch (error) {
            console.error(`Erro ao deletar Punch Item com ID ${id}:`, error);
            throw error;
        }
    }
};

export default punchItemService;
