import React, { useState, useEffect } from 'react';
import './SidebarComponent.css';

const SidebarComponent = ({ onSelectComponent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
            setIsOpen(false); // Fechar o menu ao mudar para mobile
        } else {
            setIsMobile(false);
            setIsOpen(true); // Manter o menu aberto em desktop
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Verifica o tamanho da tela na primeira renderização

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile && (
                <div className="hamburger-icon" onClick={toggleSidebar}>
                    &#9776;
                </div>
            )}
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="profile-section">
                    <img src="https://via.placeholder.com/80" alt="User Profile" className="profile-img" />
                    <h3>Robson Oliveira</h3>
                    <p>Supervice</p>
                </div>
                <nav className="nav-menu">
                    <ul>
                        <li onClick={() => onSelectComponent('Dashboard')} className="active">
                            <span className="icon">🏠</span>
                            <span className="text">Dashboard</span>
                        </li>
                        <li onClick={() => onSelectComponent('DailyReport')}>
                            <span className="icon">📁</span>
                            <span className="text">Daily Reports</span>
                        </li>
                        <li onClick={() => onSelectComponent('PointList')}>
                            <span className="icon">📊</span>
                            <span className="text">Point List</span>
                        </li>
                        <li onClick={() => onSelectComponent('WorkItemManager')}>
                            <span className="icon">👥</span>
                            <span className="text">My Team</span>
                        </li>
                        <li onClick={() => onSelectComponent('UserManager')}>
                            <span className="icon">👥</span>
                            <span className="text">Hours Team</span>
                        </li>
                        <li onClick={() => onSelectComponent('TrackInventory')}>
                            <span className="icon">📄</span>
                            <span className="text">Track TrackInventory</span>
                        </li>
                        <li onClick={() => onSelectComponent('UserList')}>
                            <span className="icon">📄</span>
                            <span className="text">Reports</span>
                        </li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <ul>
                        <li onClick={() => onSelectComponent('Settings')}>
                            <span className="icon">⚙️</span>
                            <span className="text">Settings</span>
                        </li>
                        <li onClick={() => onSelectComponent('Help')}>
                            <span className="icon">❓</span>
                            <span className="text">Help</span>
                        </li>
                    </ul>
                </div>
            </div>
            {isMobile && isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
        </>
    );
};

export default SidebarComponent;
