// config.js
const config = {
    API_URL: 'https://alphabetafinish-001-site1.etempurl.com/api',
    // API_URL: 'https://localhost:7047/api',
    withCredentials: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}` // Substitua pelo local onde você armazena o token
  }
  };
  
  export default config;
  