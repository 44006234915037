import React from 'react';
import './OverviewComponent.css';

const OverviewComponent = ({ totalPoints, completedPoints, pendingPoints, previousTotalPoints, previousCompletedPoints, previousPendingPoints }) => {

    // Função para calcular a mudança percentual
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) return current > 0 ? 100 : 0; // Evita divisão por zero
        const change = ((current - previous) / previous) * 100;
        return change.toFixed(2);
    };

    // Calcula a mudança percentual para cada métrica
    const totalPointsChange = calculatePercentageChange(totalPoints, previousTotalPoints);
    const completedPointsChange = calculatePercentageChange(completedPoints, previousCompletedPoints);
    const pendingPointsChange = calculatePercentageChange(pendingPoints, previousPendingPoints);

    // Função para determinar a classe CSS com base na mudança percentual
    const getPercentageClass = (change) => {
        return change >= 0 ? 'positive' : 'negative';
    };

    return (
        <div className="overview">
            <div className="overview-item">
                <div className="overview-content">
                    <div className="overview-label">Total Points</div>
                    <div className="overview-value">{totalPoints}</div>
                    <div className={`overview-percentage ${getPercentageClass(totalPointsChange)}`}>
                        {totalPointsChange >= 0 ? '⬆' : '⬇'} {Math.abs(totalPointsChange)}%
                    </div>
                </div>
                <div className="overview-icon">
                    <span role="img" aria-label="total-points">📊</span>
                </div>
            </div>

            <div className="overview-item">
                <div className="overview-content">
                    <div className="overview-label">Completed</div>
                    <div className="overview-value">{completedPoints}</div>
                    <div className={`overview-percentage ${getPercentageClass(completedPointsChange)}`}>
                        {completedPointsChange >= 0 ? '⬆' : '⬇'} {Math.abs(completedPointsChange)}%
                    </div>
                </div>
                <div className="overview-icon">
                    <span role="img" aria-label="completed-points">✅</span>
                </div>
            </div>

            <div className="overview-item">
                <div className="overview-content">
                    <div className="overview-label">Pending</div>
                    <div className="overview-value">{pendingPoints}</div>
                    <div className={`overview-percentage ${getPercentageClass(pendingPointsChange)}`}>
                        {pendingPointsChange >= 0 ? '⬆' : '⬇'} {Math.abs(pendingPointsChange)}%
                    </div>
                </div>
                <div className="overview-icon">
                    <span role="img" aria-label="pending-points">⏳</span>
                </div>
            </div>
        </div>
    );
};

export default OverviewComponent;
