// src/components/Admin/Admin.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.css';
import UserList from '../UserList/UserList';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ListaUsuarios from '../ListaUsuarios/ListaUsuarios';

const Admin = ({ users }) => {
  const navigate = useNavigate();

  const handleUserClick = (userId) => {
    navigate(`/admin/user/${userId}`);
  };
  const usuarios = [
    { nome: "Emma Ryan Jr.", horas: "40", statusPagamento: "Pendente", data: "Feb 19th, 2023", profilePic: "/path-to-emma-profile.jpg" },
    { nome: "Adrian Daren", horas: "38", statusPagamento: "Pago", data: "Feb 18th, 2023", profilePic: "/path-to-adrian-profile.jpg" },
    { nome: "Roxanne Hills", horas: "45", statusPagamento: "Pago", data: "Apr 16th, 2023", profilePic: "/path-to-roxanne-profile.jpg" },
  ];
  return (
    <div className="admin-container">
    <ListaUsuarios usuarios={usuarios} />
      <UserList users={users} onUserClick={handleUserClick} />
    </div>
  );
};

export default Admin;
