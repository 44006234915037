import React from 'react';
import './ProjectCard.css';
import { FaEllipsisV, FaMapMarkerAlt, FaUserCircle } from 'react-icons/fa';

const ProjectCard = ({ id, name, location, progress, daysLeft, type, onClick }) => {
  return (
    <div className="project-card" onClick={onClick}>
      <div className="card-header">
        <span>{name}</span>
        <FaEllipsisV className="card-options" />
      </div>
      <div className="card-body">
        <h3><FaMapMarkerAlt className="location-icon" /> {location}</h3>
        <p className="type">{type}</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="card-footer">
          <div className="team-avatars">
            <FaUserCircle className="avatar" />
            <FaUserCircle className="avatar" />
          </div>
          <span className="days-left">{daysLeft} Days Left</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
