import axiosInstance from './axiosConfig';

const locationService = {
    getAll: async () => {
        try {
            const response = await axiosInstance.get('/location');
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar as localizações:', error);
            throw error;
        }
    },

    getById: async (id) => {
        try {
            const response = await axiosInstance.get(`/Location/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar a localização com ID ${id}:`, error);
            throw error;
        }
    }
};

export default locationService;
