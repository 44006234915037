import axios from 'axios';
import config from '../config/config';

const API_URL = config.API_URL;

const getMaterials = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/materials`, {
            params: { projectId }  // Passa o projectId como um parâmetro de consulta (query string)
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching materials', error);
        throw error;
    }
};

const getMaterialById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/materials/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching material with id ${id}`, error);
        throw error;
    }
};

const createMaterial = async (material) => {
    try {
        const response = await axios.post(`${API_URL}/materials`, material);
        return response.data;
    } catch (error) {
        console.error('Error creating material', error);
        throw error;
    }
};

const updateMaterial = async (id, material) => {
    try {
        const response = await axios.put(`${API_URL}/materials/${id}`, material);
        return response.data;
    } catch (error) {
        console.error(`Error updating material with id ${id}`, error);
        throw error;
    }
};

const deleteMaterial = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/materials/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting material with id ${id}`, error);
        throw error;
    }
};

const getMaterialTypes = async () => {
    try {
        const response = await axios.get(`${API_URL}/MaterialType`);
        return response.data;
    } catch (error) {
        console.error('Error fetching material types', error);
        throw error;
    }
};

const getStorageLocations = async () => {
    try {
        const response = await axios.get(`${API_URL}/StorageLocation`);
        return response.data;
    } catch (error) {
        console.error('Error fetching storage locations', error);
        throw error;
    }
};

export {
    getMaterials,
    getMaterialById,
    createMaterial,
    updateMaterial,
    deleteMaterial,
    getMaterialTypes,
    getStorageLocations
};
