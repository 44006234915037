import React, { useState, useEffect } from 'react';
import { fetchUsers } from '../../services/userService'; // Atualize o caminho conforme necessário
import { getWorkItemsByUser, createWorkItem, updateWorkItemStatus, deleteWorkItem } from '../../services/workItemService';
import './WorkItemManager.css';
import Alert from '../Alert/Alert';

const WorkItemManager = ({onClose}) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ task: '', due: '', status: 'pending' });

  // Função para carregar os usuários
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const usersData = await fetchUsers();
        setUsers(usersData);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    fetchAllUsers();
  }, []);

  // Função para carregar as tarefas do usuário selecionado
  useEffect(() => {
    if (selectedUserId) {
      const fetchTasks = async () => {
        const items = await getWorkItemsByUser(selectedUserId);
        setTasks(items);
      };

      fetchTasks();
    }
  }, [selectedUserId]);

  const handleCreateTask = async () => {
    const createdItem = await createWorkItem(selectedUserId, newTask);
    if (createdItem) {
      setTasks([...tasks, createdItem]);
      setNewTask({ task: '', due: '', status: 'pending' });
    }
  };

  const handleStatusClick = async (id, status) => {
    const newStatus = status === 'completed' ? 'pending' : 'completed';
    const updatedItem = await updateWorkItemStatus(id, newStatus);
    if (updatedItem) {
      setTasks(tasks.map(task => (task.id === id ? updatedItem : task)));
    }
  };

  const handleDeleteTask = async (id) => {
    await deleteWorkItem(id);
    setTasks(tasks.filter(task => task.id !== id));
  };

  return (
    <div className="work-item-manager">
      <h2>Work Item Manager</h2>
      <div className="close-button" onClick={onClose}>&times;</div>
      
      {/* Seleção de Usuário */}
      <div className="user-selection">
        <label htmlFor="user-select">Select User:</label>
        <select
          id="user-select"
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
        >
          <option value="">--Select a User--</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.fullName}
            </option>
          ))}
        </select>
      </div>

      {/* Formulário para Criar uma Nova Tarefa */}
      {selectedUserId && (
        <div className="task-creation">
          <h3>Create New Task</h3>
          <input
            type="text"
            placeholder="Task Name"
            value={newTask.task}
            onChange={(e) => setNewTask({ ...newTask, task: e.target.value })}
          />
          <input
            type="date"
            value={newTask.due}
            onChange={(e) => setNewTask({ ...newTask, due: e.target.value })}
          />
          <button onClick={handleCreateTask}>Create Task</button>
        </div>
      )}

      {/* Lista de Tarefas */}
      {tasks.length > 0 ? (
        <div className="task-list">
          <h3>Tasks for {users.find(user => user.id === selectedUserId)?.name}</h3>
          {tasks.map((task, index) => (
            <div
              key={index}
              className={`work-item ${task.status === 'completed' ? 'completed' : ''}`}
            >
              <div className="work-item-content">
                <h4>{task.task}</h4>
                <p>Due: {task.due}</p>
              </div>
              <button
                className={`status-button ${task.status === 'completed' ? 'completed' : ''}`}
                onClick={() => handleStatusClick(task.id, task.status)}
              >
                {task.status === 'completed' ? 'Mark as Pending' : 'Mark as Completed'}
              </button>
              <button className="delete-button" onClick={() => handleDeleteTask(task.id)}>Delete</button>
            </div>
          ))}
        </div>
      ) : (
        <p>No tasks available</p>
      )}
    </div>
  );
};

export default WorkItemManager;
