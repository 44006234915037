import axiosInstance from './axiosConfig';
import { isAdmin } from '../auth/authService'; // Função para verificar se o usuário é administrador

export const getWorkItemsByUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/WorkItems/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar WorkItems por usuário:', error);
    alert('Erro ao buscar WorkItems por usuário. Tente novamente mais tarde.'); // Alerta simples
    return [];
  }
};

export const createWorkItem = async (userId, workItem) => {
  try {
    if (!isAdmin()) {
      alert('Acesso negado: Apenas administradores podem criar WorkItems.'); // Alerta simples
      throw new Error('Acesso negado: Apenas administradores podem criar WorkItems.');
    }

    const response = await axiosInstance.post('/WorkItems', {
      ...workItem,
      userId,  // O UserId deve ser enviado corretamente aqui
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao criar WorkItem:', error.response ? error.response.data : error.message);
    alert(`Erro ao criar WorkItem: ${error.response ? error.response.data : error.message}`); // Alerta simples
    return null;
  }
};

export const updateWorkItemStatus = async (id, status) => {
  try {
    if (!isAdmin()) {
      alert('Acesso negado: Apenas administradores podem atualizar WorkItems.'); // Alerta simples
      throw new Error('Acesso negado: Apenas administradores podem atualizar WorkItems.');
    }

    const response = await axiosInstance.put(`/WorkItems/${id}`, { id, status });
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar o status do WorkItem:', error.response ? error.response.data : error.message);
    alert(`Erro ao atualizar o status do WorkItem: ${error.response ? error.response.data : error.message}`); // Alerta simples
    return null;
  }
};

export const deleteWorkItem = async (id) => {
  try {
    if (!isAdmin()) {
      alert('Acesso negado: Apenas administradores podem deletar WorkItems.'); // Alerta simples
      throw new Error('Acesso negado: Apenas administradores podem deletar WorkItems.');
    }

    await axiosInstance.delete(`/WorkItems/${id}`);
    alert('WorkItem deletado com sucesso!'); // Alerta de sucesso
  } catch (error) {
    console.error('Erro ao deletar WorkItem:', error);
    alert('Erro ao deletar WorkItem. Tente novamente mais tarde.'); // Alerta simples
  }
};
