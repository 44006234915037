import React from 'react';
import './FilterComponent.css';

const FilterComponent = ({ onFilter }) => {
    return (
        <div className="filter">
            <button onClick={() => onFilter('completed')}>Completed</button>
            <button onClick={() => onFilter('pending')}>Pending</button>
        </div>
    );
};

export default FilterComponent;
