import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo-alpha-beta.jpg'; // Adjust the path if necessary

import './Header.css';

const Header = ({ isAuthenticated, onLogout }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="logo" src="../../logo192.png">
            <img src={logo} alt="Logo" className="logo" />
            </div>
            <nav className={`nav ${menuOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/track-material">Track de Material</Link></li>
                    <li><Link to="/reports">Relatórios</Link></li>
                </ul>
            </nav>
            <div className="user-actions">
                <img src="user.png" alt="Perfil" className="user-avatar" />
                <Link to="/profile" className='botaoPerfil'>Perfil</Link>
                {isAuthenticated && (
                    <button className='botaohome' onClick={onLogout}>Logout</button>
                )}
            </div>
            <div className="menu-toggle" onClick={toggleMenu}>
                <div className="hamburger"></div>
            </div>
        </header>
    );
};

export default Header;
