import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Admin from './components/Admin/Admin';
import RegisterForm from './components/RegisterForm/RegisterForm';
import Dashboard from './pages/Dashboard/Dashboard';
import MainContent from './pages/MainContent/MainContent';
import LoginForm from './components/LoginForm/LoginForm';
import ProfileView from './components/ProfileView/ProfileView';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ComingSoon from './components/ComingSoon/ComingSoon';
import PointListComponent from './components/PointListComponent/PointListComponent';
import PointListPage from './pages/PointListPage/PointListPage';
import DashboardPageSlideBar from './pages/DashboardPage/DashboardPageSlideBar'

function App() {
  const [auth, setAuth] = useState({ isAuthenticated: false, token: null });
  const navigate = useNavigate();

  useEffect(() => {
    const authData = localStorage.getItem('authToken');
    if (authData) {
      try {
        const parsedAuthData = JSON.parse(authData);
        const { token, expirationTime } = parsedAuthData;
        if (new Date().getTime() < expirationTime) {
          setAuth({ isAuthenticated: true, token });
        } else {
          localStorage.removeItem('authToken');
        }
      } catch (e) {
        console.error("Error parsing auth data from localStorage", e);
        localStorage.removeItem('authToken');
      }
    }
  }, []);

  const login = (token) => {
    const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutos em milissegundos
    const authData = { token, expirationTime };
    localStorage.setItem('authToken', JSON.stringify(authData));
    setAuth({ isAuthenticated: true, token });
    navigate('/');
  };

  const logout = () => {
    setAuth({ isAuthenticated: false, token: null });
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  return (
    <div className="App">
      <Routes>
        {!auth.isAuthenticated ? (
          <>
            <Route path="/" element={<ComingSoon/>} />
            <Route path="/login" element={<LoginForm onLogin={login} />} />
            <Route path="/register" element={<RegisterForm />} />
          </>
        ) : (
          <>
            <Route
              path="/"
              element={
                <>
                  <Header isAuthenticated={auth} onLogout={logout} />
                  <Dashboard />
                  <Footer />
                </>
              }
            />
            <Route
              path="/main-content/:projectId"
              element={
                <>
                  <DashboardPageSlideBar/>
                </>
              }
            />
            <Route
              path="/main-content"
              element={
                <>
                  <DashboardPageSlideBar />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <Header isAuthenticated={auth} onLogout={logout} />
                  <ProfileView />
                  <Footer />
                </>
              }
            />
            <Route
              path="/admin"
              element={
                <>
                  <Header isAuthenticated={auth} onLogout={logout} />
                  <Admin />
                  <Footer />
                </>
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
