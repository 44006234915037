import React, { useEffect, useState } from 'react';
import './WorkList.css';
import { getWorkItemsByUser, updateWorkItemStatus } from '../../services/workItemService';

const WorkList = () => {
  const [tasks, setTasks] = useState([]);
  const userId = localStorage.getItem('userId'); // Pegando o userId do localStorage

  useEffect(() => {
    const fetchWorkItems = async () => {
      if (userId) {
        const items = await getWorkItemsByUser(userId);
        setTasks(items);
      }
    };

    fetchWorkItems();
  }, [userId]);

  const handleStatusClick = async (id, status) => {
    const newStatus = status === 'completed' ? 'pending' : 'completed';

    // Atualiza o status no backend
    const updatedItem = await updateWorkItemStatus(id, newStatus);

    if (updatedItem) {
      // Atualiza o estado local imediatamente após a resposta da API
      setTasks(tasks =>
        tasks.map(task =>
          task.id === id ? { ...task, status: newStatus } : task
        )
      );
    }
  };

  return (
    <div className="work-list">
      <h2>Work List</h2>
      <p>Todo</p>
      {tasks.length > 0 ? (
        tasks.map((task, index) => (
          <div
            key={index}
            className={`work-item ${task.status === 'completed' ? 'completed' : ''}`}
          >
            <div className="work-item-indicator"></div>
            <div className="work-item-content">
              <h4>{task.task}</h4>
              <p>{task.due}</p>
            </div>
            <button
              className={`status-button ${task.status === 'completed' ? 'completed' : ''}`}
              onClick={() => handleStatusClick(task.id, task.status)}
            >
              {task.status === 'completed' ? 'Completed' : 'Mark as completed'}
            </button>
          </div>
        ))
      ) : (
        <p>No tasks available</p>
      )}
    </div>
  );
};

export default WorkList;
