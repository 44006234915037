import React, { useState, useEffect } from 'react';
import SearchComponent from '../SearchComponent/SearchComponent';
import punchItemService from '../../../services/punchItemService';
import statusService from '../../../services/statusService';
import priorityService from '../../../services/priorityService';
import locationService from '../../../services/locationService';
import PointListComponent from '../PointListComponent';
import './TransactionListComponent.css';

const TransactionListComponent = ({ onSearch, onViewDetails }) => {
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItemId, setSelectedItemId] = useState(null);

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const pointsData = await punchItemService.getAll();
                console.log(pointsData)
                const updatedPoints = await Promise.all(pointsData.map(async point => {
                    const status = await statusService.getById(point.statusId);
                    const priority = await priorityService.getById(point.priorityId);
                    const location = await locationService.getById(point.locationId);

                    return {
                        ...point,
                        status: status?.name || 'N/A',
                        priority: priority?.level || 'N/A',
                        location: location?.level || 'N/A',
                        unit: location?.unit || 'N/A',
                    };
                }));

                setPoints(updatedPoints);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar os detalhes dos Punch Items:', error);
                setLoading(false);
            }
        };

        fetchPoints();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    if (selectedItemId !== null) {
        return (
            <PointListComponent itemId={selectedItemId}      onBack={() => setSelectedItemId(null)}/>
        );
    }
    return (
        <div className="transaction-list">
            <h3 className="transaction-title">Transaction</h3>
            <div className="transaction-search-filter">
                <SearchComponent onSearch={onSearch} />
                <button className="filter-btn">Filter</button>
            </div>
            <table className="transaction-table">
                <thead>
                    <tr>
                        <th>Point</th>
                        <th>Floor</th>
                        <th>Unit</th>
                        <th>Status</th>
                        <th>Priority</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {points.map((point, index) => (
                        <tr key={index}>
                            <td>{point.description}</td>
                            <td>{point.location}</td>
                            <td>{point.unit}</td>
                            <td>
                                <span className={`status-label ${point.status?.toLowerCase()}`}>
                                    {point.status}
                                </span>
                            </td>
                            <td>
                                <span className={`priority-label ${point.priority?.toLowerCase()}`}>
                                    {point.priority}
                                </span>
                            </td>
                            <td>
                            <button className="view-btn" onClick={() => setSelectedItemId(point.id)}>
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionListComponent;
