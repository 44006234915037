import React from 'react';
import './ListaUsuarios.css';

const ListaUsuarios = ({ users, onUserSelect, onTimeBankSelect }) => {
  return (
    <div className="lista-usuarios">
      <div className="table-header">
        <input type="text" placeholder="Search..." className="search-box" />
      </div>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Horas</th>
            <th>Status do Pagamento</th>
            <th>Data</th>
            <th>Detalhes</th>
            <th>Banco de Horas</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.firstName}</td>
              <td>{user.horas} horas</td>
              <td>{user.statusPagamento}</td>
              <td>{user.data}</td>
              <td ><button className='details-button' onClick={() => onUserSelect(user)}>Detalhes</button></td>
              <td ><button className='details-button' onClick={() => onTimeBankSelect(user)}>Banco de Horas</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaUsuarios;
