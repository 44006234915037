import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import workSessionService from '../../services/workSessionService';
import { getAllProjects } from '../../services/projectService';
import './WorkSessionTracker.css';
import Loading from '../Loading/Loading';

const WorkSessionTracker = ({ user, selectedUserId, workerId, isUser, onClose, refreshTrigger  }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [summary, setSummary] = useState([]);
  const [weeklyTotalHours, setWeeklyTotalHours] = useState(0);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);


  const userId =selectedUserId ;
  const workerID = !isUser ? workerId : null;
  const localTimeZone = moment.tz.guess(); // Automatically guess the user's local timezone

  useEffect(() => {

    const fetchProjectsAndSessions = async () => {
      try {
        const projectsData = await getAllProjects();
        setProjects(projectsData);

        const summaryResponse = await workSessionService.getDailySummary(selectedUserId || workerID, isUser);
        setSummary(summaryResponse.data);


        const today = moment().tz(localTimeZone).format('YYYY-MM-DD');
        const sessionToday = summaryResponse.data.find(item => moment(item.startTime).tz(localTimeZone).format('YYYY-MM-DD') === today);
        if (sessionToday) {
          setSession(sessionToday);
        }

        const weeklyTotalHoursResponse = await workSessionService.getWeeklyTotalHours(userId || workerID, isUser);
        setWeeklyTotalHours(weeklyTotalHoursResponse.data);
      } catch (error) {
        setError('There was an error fetching data!');
      } finally {
        setLoading(false);
      }
    };

    fetchProjectsAndSessions();
  }, [userId, workerID, isUser,localTimeZone,refreshTrigger]);

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
      0.5 - Math.cos(dLat) / 2 +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      (1 - Math.cos(dLon)) / 2;
    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const checkLocation = (callback) => {
   
    if (navigator.geolocation && selectedProject) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        const projectLocation = { lat: selectedProject.latitude, lng: selectedProject.longitude };
        const distance = getDistanceFromLatLonInKm(latitude, longitude, projectLocation.lat, projectLocation.lng);


        if (distance < 0.6) {
          callback();
        } else {
          showAlert('You are not at the required location.');
        }
      }, () => {
        showAlert('Unable to retrieve your location.');
      });
    } else {
      showAlert('Geolocation is not supported by this browser or no project selected.');
    }
  };

  const showAlert = (message) => {
    const alertBox = document.getElementById('alert-box');
    alertBox.innerText = message;
    alertBox.style.display = 'block';
    setTimeout(() => {
      alertBox.style.display = 'none';
    }, 3000);
  };

  const toISOStringWithTimezone = (date) => {
    return moment(date).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ssZ');
  };
  

  const startLunchBreak = () => {
    checkLocation(async () => {
      if (session && session.sessionId && !session.lunchStartTime) {
        try {
          const updatedSession = { ...session, lunchStartTime: toISOStringWithTimezone(new Date()) };
          await workSessionService.startLunchBreak(session.sessionId);
          setSession(updatedSession);
        } catch (error) {
          setError('There was an error starting the lunch break!');
        }
      } else if (session && session.lunchStartTime && !session.lunchEndTime) {
        showAlert('Lunch break already started, please end lunch break.');
      } else {
        showAlert('No active session to start lunch break.');
      }
    });
  };

  const endLunchBreak = () => {
    checkLocation(async () => {
      if (session && session.sessionId && session.lunchStartTime && !session.lunchEndTime) {
        try {
          const updatedSession = { ...session, lunchEndTime: toISOStringWithTimezone(new Date()) };
          await workSessionService.endLunchBreak(session.sessionId);
          setSession(updatedSession);
        } catch (error) {
          setError('There was an error ending the lunch break!');
        }
      } else if (session && !session.lunchStartTime) {
        showAlert('Lunch break has not started yet.');
      } else {
        showAlert('Lunch break already ended or no active session.');
      }
    });
  };

  const endSession = () => {
    checkLocation(async () => {
      if (session && session.sessionId && !session.endTime) {
        try {
          const updatedSession = { ...session, endTime: toISOStringWithTimezone(new Date()) };
          await workSessionService.endSession(session.sessionId);
          setSession(null);

          const summaryResponse = await workSessionService.getDailySummary(userId || workerID, isUser);
          setSummary(summaryResponse.data);
          const weeklyTotalHoursResponse = await workSessionService.getWeeklyTotalHours(userId || workerID, isUser);
          setWeeklyTotalHours(weeklyTotalHoursResponse.data);
        } catch (error) {
          setError('There was an error ending the session!');
        }
      } else if (session && session.endTime) {
        showAlert('Session already ended.');
      } else {
        showAlert('No active session to end.');
      }
    });
  };

  const handleStartSession = () => {
    checkLocation(async () => {
        const today = moment().tz('America/New_York').format('YYYY-MM-DD');
        const sessionToday = summary.find(item => moment(item.startTime).tz('America/New_York').format('YYYY-MM-DD') === today);

        if (sessionToday) {
            if (sessionToday.endTime) {
                showAlert('You have already ended your work session for today. Great job!');
                return;
            } else {
                setSession(sessionToday);
                showAlert('Continuing your existing work session.');
                return;
            }
        }

        try {
            const localStartTime = new Date();
            let newSession;
            if (workerID != null) {
                newSession = {
                    projectId: selectedProject.id,
                    userId: userId || workerID,
                    startTime: toISOStringWithTimezone(localStartTime),
                    isEmployee: true // Define isEmployee as true if workerID is not null
                };
            } else {
                newSession = {
                    projectId: selectedProject.id,
                    userId: userId,
                    startTime: toISOStringWithTimezone(localStartTime),
                    isEmployee: false // Set isEmployee to false if workerID is null
                };
            }
          
            const newSessionResponse = await workSessionService.startSession(newSession);
            setSession(newSessionResponse.data);

            // Reload data after starting the session
            const summaryResponse = await workSessionService.getDailySummary(userId || workerID, isUser);
            setSummary(summaryResponse.data);
            const weeklyTotalHoursResponse = await workSessionService.getWeeklyTotalHours(userId || workerID, isUser);
            setWeeklyTotalHours(weeklyTotalHoursResponse.data);
        } catch (error) {
            let errorMessage = 'An error occurred while starting the work session.';
            
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = 'Bad request: ' + (error.response.data.message || 'Please check the provided data.');
                        break;
                    case 401:
                        errorMessage = 'You are not authorized to perform this action.';
                        break;
                    case 404:
                        errorMessage = 'Resource not found: ' + (error.response.data.message || 'Please check the provided data.');
                        break;
                    case 500:
                        errorMessage = 'Internal server error: ' + (error.response.data.message || 'Please try again later.');
                        break;
                    default:
                        errorMessage = 'Error: ' + (error.response.data.message || 'Something went wrong.');
                        break;
                }
            } else if (error.request) {
                errorMessage = 'Could not connect to the server. Please check your internet connection.';
            } else {
                errorMessage = 'Error: ' + error.message;
            }

            setError(errorMessage);
            showAlert(errorMessage);
        }
    });
};

  
  const renderActionButtons = () => {
    if (!session) {
      return (
        <button className="action-button" onClick={handleStartSession}>
          Start Work Session
        </button>
      );
    }

    if (session.startTime && !session.lunchStartTime) {
      return (
        <button className="action-button" onClick={startLunchBreak}>
          Start Lunch Break
        </button>
      );
    }

    if (session.lunchStartTime && !session.lunchEndTime) {
      return (
        <button className="action-button" onClick={endLunchBreak}>
          End Lunch Break
        </button>
      );
    }

    if (session.lunchEndTime && !session.endTime) {
      return (
        <button className="action-button" onClick={endSession}>
          End Work
        </button>
      );
    }

    return null;
  };

  if (loading) return <Loading />;

  return (
    <div className="work-session-tracker">
      <h1>Work Session Tracker {user && `for ${user.fullName}`}</h1>
      {error && <p className="error-text">{error}</p>}
      <div className="close-button" onClick={onClose}>&times;</div>

      {session && session.endTime ? (
        <div className="work-completed-message">
          <p>You have completed your work for today. Great job!</p>
        </div>
      ) : (
        <>
          <div id="alert-box" className="alert-box"></div>
          <div className="project-select">
            <label htmlFor="project">Select Project:</label>
            <select id="project" onChange={(e) => setSelectedProject(projects.find(project => project.id === parseInt(e.target.value)))}>
              <option value="">--Select a project--</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          {renderActionButtons()}
          {session && (
  <div className="session-info">
    <p><span className="info-label">Start Time:</span> <span className="info-value">{moment(session.startTime).tz('America/New_York').format('HH:mm:ss')}</span></p>
    <p><span className="info-label">Lunch Time:</span> <span className="info-value">{session.lunchStartTime && session.lunchEndTime ? `${moment(session.lunchStartTime).tz('America/New_York').format('HH:mm:ss')} - ${moment(session.lunchEndTime).tz('America/New_York').format('HH:mm:ss')}` : 'N/A'}</span></p>
    <p><span className="info-label">End Time:</span> <span className="info-value">{session.endTime ? moment(session.endTime).tz('America/New_York').format('HH:mm:ss') : 'N/A'}</span></p>
  </div>
)}

        </>
      )}
      <h2>Daily Summary</h2>
      <ul className="summary-list">
        {summary.map((item, index) => (
          <li key={index} className="summary-item">
            <p><span className="info-label">Date:</span> <span className="info-value">{moment(item.startTime).tz('America/New_York').format('YYYY-MM-DD')}</span></p>
            <p><span className="info-label">Start Time:</span> <span className="info-value">{moment(item.startTime).tz('America/New_York').format('HH:mm:ss')}</span></p>
            <p><span className="info-label">Lunch Time:</span> <span className="info-value">{item.lunchStartTime && item.lunchEndTime ? `${moment(item.lunchStartTime).tz('America/New_York').format('HH:mm:ss')} - ${moment(item.lunchEndTime).tz('America/New_York').format('HH:mm:ss')}` : 'N/A'}</span></p>
            <p><span className="info-label">End Time:</span> <span className="info-value">{item.endTime ? moment(item.endTime).tz('America/New_York').format('HH:mm:ss') : 'N/A'}</span></p>
            <p><span className="info-label">Total Hours:</span> <span className="info-value">{((moment(item.endTime || new Date()).diff(moment(item.startTime), 'minutes') / 60).toFixed(2))}</span></p>
          </li>
        ))}
      </ul>
      <h2>Weekly Total Hours: <span className="info-value">{weeklyTotalHours ? weeklyTotalHours.toFixed(2) : 'N/A'}</span></h2>
    </div>
  );
};

export default WorkSessionTracker;
