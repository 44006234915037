import axios from 'axios';

import config from '../config/config';
const API_URL = config.API_URL;

// const API_URL = 'https://alphabetafinish-001-site1.etempurl.com/api/users';
// const API_URLEmploy = 'https://alphabetafinish-001-site1.etempurl.com/api/employees';

// const API_URL = 'https://localhost:7047/api/users';
// const API_URLEmploy = 'https://localhost:7047/api/employees';


const fetchUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch users', error);
    throw new Error('Failed to fetch users');
  }
};

const saveUser = async (user) => {
  try {
    if (user.id === 0 || user.id === undefined) {
      const response = await axios.post(`${API_URL}/users`, user);
      return response.data;
    } else {
      const response = await axios.put(`${API_URL}/users/${user.id}`, user);
      return response.data;
    }
  } catch (error) {
    console.error('Failed to save user', error);
    throw new Error('Failed to save user');
  }
};

const fetchEmployees = async () => {
  try {
    const response = await axios.get(`${API_URL}/employees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

const createUser = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/users`, user);
    return response.data;
  } catch (error) {
    console.error('Failed to create user', error);
    throw new Error('Failed to create user');
  }
};


const updateUser = async (id, user) => {
  try {
    const response = await axios.put(`${API_URL}/users/${id}`, user);
    return response.data;
  } catch (error) {
    console.error('Failed to update user', error);
    throw new Error('Failed to update user');
  }
};

const deleteUser = async (id) => {
  try {
    await axios.delete(`${API_URL}/users/${id}`);
    return { id };
  } catch (error) {
    console.error('Failed to delete user', error);
    throw new Error('Failed to delete user');
  }
};

const getUserById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user', error);
    throw new Error('Failed to fetch user');
  }
};

const getAllUsers = async () => {
  try {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.get(`${API_URL}/users`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch users', error);
    throw new Error('Failed to fetch users');
  }
};

export { fetchUsers, saveUser, fetchEmployees, createUser, updateUser, deleteUser, getUserById, getAllUsers };
