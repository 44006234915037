import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import workerService from '../../services/workerService';
import './WorkerForm.css';

const WorkerForm = ({ workerId, onClose }) => {
    const [worker, setWorker] = useState({
        name: '',
        position: '',
        phone: '',
        signature: '',
        workerType: '',
        w9: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const sigCanvas = useRef({});

    useEffect(() => {
        if (workerId) {
            // Fetch worker data if workerId is provided
            workerService.getWorkerById(workerId).then(response => {
                setWorker(response);
                if (response.signature) {
                    sigCanvas.current.fromDataURL(response.signature);
                }
            }).catch(error => {
                setError('There was an error fetching the worker data.');
            });
        }
    }, [workerId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWorker({
            ...worker,
            [name]: value
        });
    };

    const handleSignature = () => {
        setWorker({
            ...worker,
            signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (workerId) {
                // Update existing worker
                await workerService.updateWorker(workerId, worker);
                setSuccess('Worker successfully updated.');
            } else {
                // Add new worker
                await workerService.addWorker(worker);
                setSuccess('Worker successfully registered.');
                // Clear the form
                setWorker({
                    name: '',
                    position: '',
                    phone: '',
                    signature: '',
                    workerType: '',
                    w9: ''
                });
                sigCanvas.current.clear();
            }
            setError('');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('There was an error submitting the form.');
            }
            setSuccess('');
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setWorker({
            ...worker,
            signature: ''
        });
    };

    return (
        <div className="worker-form">
            <div className="close-button" onClick={onClose}>&times;</div>

            <h2>{workerId ? 'Update Worker' : 'Register Worker'}</h2>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input type="text" name="name" value={worker?.name || ''} onChange={handleChange} required />
                </div>
                <div>
                    <label>Position:</label>
                    <input type="text" name="position" value={worker?.position || ''} onChange={handleChange} />
                </div>
                <div>
                    <label>Phone:</label>
                    <input type="tel" name="phone" value={worker?.phone || ''} onChange={handleChange} />
                </div>
                <div>
                    <label>Signature:</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        onEnd={handleSignature}
                    />
                    <button type="button" onClick={clearSignature}>Clear Signature</button>
                </div>
                <div>
                    <label>Worker Type:</label>
                    <select name="workerType" value={worker?.workerType || ''} onChange={handleChange} required>
                        <option value="">Select Worker Type</option>
                        <option value="Help">Help</option>
                        <option value="Fixed">Fixed</option>
                    </select>
                </div>
                <div>
                    <label>W9:</label>
                    <select name="w9" value={worker?.w9 || ''} onChange={handleChange} required>
                        <option value="">Select W9 Status</option>
                        <option value="Has">Has</option>
                        <option value="Does not have">Does not have</option>
                    </select>
                </div>
                <button type="submit">{workerId ? 'Update Worker' : 'Register Worker'}</button>
            </form>
        </div>
    );
};

export default WorkerForm;
