import axios from 'axios';
import config from '../config/config';


const API_URL = config.API_URL;
// const API_URL = 'https://localhost:7047/api/DailyReport';


export const fetchReports = async () => {
    try {
        const response = await axios.get(`${API_URL}/DailyReport`);
        return response.data;
    } catch (error) {
        console.error('Error fetching reports:', error);
        throw error;
    }
};
export const fetchReportById = async (id) => {
    const response = await axios.get(`${API_URL}/DailyReport/${id}`);
    return response.data;
};
export const saveReport = async (report) => {
    try {
        if (report.reportID === 0) {
            await axios.post(API_URL, report);
        } else {
            await axios.put(`${API_URL}/DailyReport/${report.reportID}`, report);
        }
    } catch (error) {
        console.error('Error saving report:', error);
        throw error;
    }
};

export const deleteReport = async (id) => {
    try {
        await axios.delete(`${API_URL}/DailyReport/${id}`);
    } catch (error) {
        console.error('Error deleting report:', error);
        throw error;
    }
};
